import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { useConfigStore } from "@/stores/config";
import i18n from "@/core/plugins/i18n";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/default-layout/DefaultLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: 'pages.dashboard.pageTitle',
          breadcrumbs: [
            // { label: "pages.dashboard.breadcrumbs", path: "/dashboard" }
          ],
        },
      },
      {
        path: "/account",
        name: "account",
        component: () => import("@/views/account/Account.vue"),
        meta: {
          breadcrumbs: [
            { label: 'pages.account.breadcrumbs.items' }
          ],
        },
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/account/Overview.vue"),
            meta: {
              pageTitle: 'pages.account.pageTitleOverview',
              breadcrumbs: [
                { label: 'pages.account.breadcrumbs.items' }
              ],
            },
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/account/Settings.vue"),
            meta: {
              pageTitle: 'pages.account.pageTitleSettings',
              breadcrumbs: [
                { label: 'pages.account.breadcrumbs.items' }
              ],
            },
          },
          {
            path: "notifications",
            name: "account-notifications",
            component: () => import("@/views/account/Notifications.vue"),
            meta: {
              pageTitle: 'pages.account.pageTitleNotifications',
              breadcrumbs: [
                { label: 'pages.account.breadcrumbs.items' }
              ],
            },
          },
        ],
      },
      {
        path: "/groups",
        name: "groups",
        component: () => import("@/views/pages/Groups.vue"),
        meta: {
          pageTitle: 'pages.groups.pageTitle',
          breadcrumbs: [
            { label: 'pages.groups.breadcrumbs.groups' }
          ],
        },
      },
      {
        path: "/search",
        name: "search",
        component: () => import("@/views/pages/Search.vue"),
        meta: {
          pageTitle: 'pages.items.pageTitle',
          breadcrumbs: [
            { label: 'pages.items.breadcrumbs.items' }
          ],
        },
      },
      {
        path: "/search/:id",
        name: "search-id",
        component: () => import("@/views/items/SearchDetails.vue"),
        meta: {
          breadcrumbs: [
            { label: 'pages.items.breadcrumbs.items', path: '/search' },
            { label: 'pages.searchId.breadcrumbs.items' }
          ],
        },
        children: [
          {
            path: "items",
            name: "search-id-list",
            component: () => import("@/views/items/List.vue"),
            meta: {
              pageTitle: 'pages.searchId.pageTitleList',
              breadcrumbs: [
                { label: 'pages.items.breadcrumbs.items', path: '/search' },
                { label: 'pages.searchId.breadcrumbs.items' },
                // { label: 'pages.searchId.pageTitleList' }
              ],
            },
          },
          {
            path: "analytics",
            name: "search-id-analytics",
            component: () => import("@/views/items/Analytics.vue"),
            meta: {
              pageTitle: 'pages.searchId.pageTitleAnalytics',
              breadcrumbs: [
                { label: 'pages.items.breadcrumbs.items', path: '/search' },
                { label: 'pages.searchId.breadcrumbs.items' },
                // { label: 'pages.searchId.pageTitleAnalytics' }
              ],
            },
          },
        ],
      },
/*       {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/LayoutBuilder.vue"),
        meta: {
          pageTitle: "Layout Builder",
          breadcrumbs: [
            { label: "Layout", path: "/builder" }
          ],
        },
      }, */
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    meta: {
      middleware: "no-auth",
    },
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: 'pages.authentication.signIn.pageTitle'
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: 'pages.authentication.signUp.pageTitle'
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: 'pages.authentication.passwordReset.pageTitle'
        },
      },
      {
        path: "/password-change",
        name: "password-change",
        component: () => import("@/views/authentication/basic-flow/PasswordChange.vue"),
        meta: {
          pageTitle: 'pages.authentication.passwordChange.pageTitle'
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    meta: {
      middleware: "no-auth",
    },
    children: [
      {
        path: "/welcome",
        name: "welcome",
        component: () => import("@/views/authentication/Welcome.vue"),
        meta: {
          pageTitle: 'pages.authentication.welcome.pageTitle'
        },
      },
      {
        path: "/verify-email",
        name: "verify-email",
        component: () => import("@/views/authentication/VerifyEmail.vue"),
        meta: {
          pageTitle: 'pages.authentication.verifyEmail.pageTitle'
        },
      },
      {
        path: "/password-confirmation",
        name: "password-confirmation",
        component: () => import("@/views/authentication/PasswordConfirmation.vue"),
        meta: {
          pageTitle: 'pages.authentication.passwordConfirmation.pageTitle'
        },
      },
      {
        path: "/account-deactivated",
        name: "account-deactivated",
        component: () => import("@/views/authentication/AccountDeactivated.vue"),
        meta: {
          pageTitle: 'pages.authentication.accountDeactivated.pageTitle'
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/authentication/Error404.vue"),
        meta: {
          pageTitle: 'pages.authentication.error404.pageTitle'
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/authentication/Error500.vue"),
        meta: {
          pageTitle: 'pages.authentication.error500.pageTitle'
        },
      },
    ],
  },
  {
    path: '/oauth-callback',
    name: 'OAuthCallback',
    component: () => import("@/components/oauth/OAuthCallback.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_APP_BASE_URL),
  routes,
  scrollBehavior(to) {
    // If the route has a hash, scroll to the section with the specified ID; otherwise, scroll toc the top of the page.
    if (to.hash) {
      return {
        el: to.hash,
        top: 80,
        behavior: "smooth",
      };
    } else {
      return {
        top: 0,
        left: 0,
        behavior: "smooth",
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  const { t } = i18n.global; // Используйте i18n.global для получения функции перевода
  const authStore = useAuthStore();
  const configStore = useConfigStore();

  // Перевод заголовка страницы
  if (to.meta.pageTitle) {
    to.meta.pageTitle = t(to.meta.pageTitle);
    document.title = `${to.meta.pageTitle} - ${import.meta.env.VITE_APP_NAME}`;
  }

  // Перевод хлебных крошек
  if (to.meta.breadcrumbs) {
    to.meta.breadcrumbs = to.meta.breadcrumbs.map(breadcrumb => {
      return {
        label: t(breadcrumb.label),
        path: breadcrumb.path || null,
      };
    });
  }

  // Сброс конфигурации до начального состояния
  configStore.resetLayoutConfig();

  // Проверка аутентификационного токена перед каждой сменой страницы
  authStore.verifyAuth();

  // Проверка, требуется ли аутентификация для доступа к странице
  if (to.meta.middleware === "auth") {
    if (authStore.isAuthenticated) {
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else if (to.meta.middleware === "no-auth") {
    if (!authStore.isAuthenticated) {
      next();
    } else {
      next({ name: "dashboard" });
    }
  } else {
    next();
  }
});

export default router;