import { watch } from "vue";
import { setLocale } from 'yup';
import i18n from "@/core/plugins/i18n";

function updateYupLocale() {
  const { t } = i18n.global;

  setLocale({
    mixed: {
      required: t('validation.required'),
    },
    string: {
      min: ({ min }) => t('validation.min', { min }),
      max: ({ max }) => t('validation.max', { max }),
      email: t('validation.email'),
      url: t('validation.url'),
    },
    // Добавьте другие типы проверок и их сообщения об ошибках по мере необходимости
  });
}

watch(i18n.global.locale, (newLocale) => {
  updateYupLocale();
});

updateYupLocale();